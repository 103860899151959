<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { useAddresses } from '/~/composables/addresses'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'drawer-address',
  components: {
    BaseButton,
    BaseIcon,
    BaseMenu,
    BaseLoader,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { removeAddress, makeAddressDefault } = useAddresses()
    const { isUsProvider, isGbProvider, isCaProvider } = useProvider()

    return {
      removeAddress,
      makeAddressDefault,
      isUsProvider,
      isGbProvider,
      isCaProvider,
    }
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    menuItems() {
      return [
        {
          icon: 'plain/edit2',
          content: 'Edit address',
          click: () => {
            const route = this.$route.meta.itemRoute
              ? `${this.$route.meta.itemRoute}/${this.address.id}`
              : {
                  hash: `#profile-add-address/${this.address.id}`,
                }

            this.$router.push(route)
          },
        },
        {
          icon: 'trash',
          content: 'Delete address',
          hidden: this.address.default,
          click: async () => {
            this.processing = true
            await this.removeAddress(this.address)
            this.processing = false
          },
        },
        {
          icon: 'plain/check',
          content: 'Set as Default',
          hidden: this.address.default,
          click: async () => {
            this.processing = true
            await this.makeAddressDefault(this.address)
            this.processing = false
          },
        },
      ]
    },
  },
}
</script>

<template>
  <div class="relative flex justify-between rounded-xl bg-gray-50 px-6 py-5">
    <div class="space-y-1.5 text-sm" :class="processing && 'opacity-50'">
      <div v-if="address.companyName">
        {{ address.companyName }}
      </div>
      <div class="text-base font-bold text-eonx-neutral-800">
        {{ address.firstName }} {{ address.lastName }}
      </div>
      <div class="text-eonx-neutral-600">
        {{ address.streetAddress }}
      </div>
      <div class="text-eonx-neutral-600">
        <template v-if="isUsProvider">
          {{ address.suburb }}, {{ address.state.toUpperCase() }}
          {{ address.postcode }}
        </template>
        <template v-else-if="isCaProvider">
          {{ address.suburb }}, {{ address.state.toUpperCase() }}
          <br />
          {{ address.postcode }}
        </template>
        <template v-else-if="isGbProvider">
          {{ address.suburb }}
          <br />
          {{ address.state.toUpperCase() }}
          <br />
          {{ address.postcode }}
        </template>
        <template v-else>
          {{ address.suburb }} {{ address.state.toUpperCase() }}
          {{ address.postcode }}
        </template>
      </div>
      <div
        v-if="address.default"
        class="flex items-center font-bold"
        :style="{
          color: '#10B981',
        }"
      >
        <base-icon svg="plain/check" size="sm" class="mr-2.5" />
        Default
      </div>
    </div>
    <div
      class="flex flex-shrink-0 flex-col items-end justify-center"
      :class="processing && 'opacity-50'"
    >
      <base-menu
        :items="menuItems(address)"
        position="right"
        class="-mr-2.5"
        :class="processing && 'pointer-events-none'"
      >
        <template #trigger>
          <base-button
            icon="plain/more"
            title="Address"
            alt="More"
            class="flex self-center text-eonx-neutral-800"
          />
        </template>
      </base-menu>
    </div>
    <base-loader v-if="processing" fullwidth class="absolute h-full" />
  </div>
</template>
